import * as types from '../types'
import * as reservationSelectors from '../selectors'
import {changeField} from './general'

import {earlyCheckInService, lateCheckOutService,} from '../../helpers/servicesNames'

import {defaultCheckInTime, defaultCheckOutTime,} from '../../helpers/defaultTimes'
import {LogsAPI} from '../../logs-api'
import {sessionSelectors} from '../../session'
import {getDatesForCopiedService} from '../helpers'
import {currentDateSelectors} from '../../current-date'
import {specialServiceTypesSelectors} from '../../special-service-types'
import {callModal, modalTemplates} from '../../../Components/dynamic-modal'

function findService(id, state) {
    const services = reservationSelectors.services(state)
    return services?.find(item => item.id === id)
}

function getActualDate(service) {
    return service.service_type === 'breakfast'
        ? service.breakfast_delivery_date
        : service.date
}

function withLogging(actionCreator, callback) {
    return params => (dispatch, getState) => {
        const state = getState()

        const service = findService(params.id, state)

        if (service) {
            callback(service)
        }

        dispatch(actionCreator(params))
    }
}

const normalizers = {
    price: value => {
        return Number(value) || ''
    },
    quantity: value => {
        return Number(value) || ''
    },
}

export const createService = ({
                                  serviceType,
                                  name,
                                  price,
                                  quantity,
                                  paymentType,
                                  date,
                                  special_service_id,
                                  breakfast_pk = '',
                                  breakfast_delivery_date = '',
                                  parking_car_numbers = []
                              }) => (dispatch, getState) => {
    const state = getState()
    const adminName = sessionSelectors.adminName(state)

    dispatch({
        type: types.CREATE_SERVICE,
        payload: {
            serviceType,
            name,
            price: normalizers.price(price),
            quantity: normalizers.quantity(quantity),
            paymentType,
            date,
            special_service_id,
            breakfast_pk,
            breakfast_delivery_date,
            parking_car_numbers,
            adminName,
        },
    })

    const actualDate = getActualDate({
        service_type: serviceType,
        date,
        breakfast_delivery_date,
    })
console.log('olololo', parking_car_numbers)
    LogsAPI.custom(
        `Создана услуга ${name}` +
        ` за ${actualDate}` +
        ` со стоимостью ${price},` +
        ` методом оплаты "${paymentType || '-'}"` +
        ` и количеством ${quantity}` +
        `${parking_car_numbers?.length ? ', Номера: ' + parking_car_numbers.join(', ' ) : ''}`

    )
}

const createUpdater = (fieldName, logName, normalizer = value => value) => ({
                                                                                id,
                                                                                value,
                                                                            }) => (dispatch, getState) => {
    const state = getState()

    dispatch({
        type: types.UPDATE_SERVICE,
        payload: {
            id,
            updates: {
                [fieldName]: normalizer(value),
            },
        },
    })

    const service = findService(id, state)

    const {service: name} = service

    LogsAPI.custom(
        `Изменено поле "${logName}" услуги "${name}" за ${getActualDate(
            service
        )}. Новое значение: "${value}"`
    )
}

export const updateBreakfastType = createUpdater('breakfast_pk', 'Тип завтрака')

export const updateServiceDate = createUpdater('date', 'Дата')

export const updateCarNumber = createUpdater('parking_car_numbers', 'Номер автомобиля')

export const updateServiceDeliveryDate = createUpdater(
    'breakfast_delivery_date',
    'Дата доставки'
)

export const updateServicePrice = createUpdater(
    'price',
    'Стоимость',
    normalizers.price
)

export const updateServiceQuantity = createUpdater(
    'quantity',
    'Количество',
    normalizers.quantity
)

export const updateServicePaymentType = createUpdater(
    'payment_type',
    'Метод оплаты'
)

export const copyServiceAction = ({id, overrides = {}}) => (
    dispatch,
    getState
) => {
    const state = getState()
    const adminName = sessionSelectors.adminName(state)

    const service = findService(id, state)

    const availableDates = reservationSelectors.availableDates(state)
    const availableBreakfastDates = reservationSelectors.availableBreakfastDates(
        state
    )

    const reservationStart = reservationSelectors.reservationStart(state)
    const today = currentDateSelectors.today(state)

    if (!service) {
        throw new Error('Service not found')
    }

    const datesObject = getDatesForCopiedService({
        service,
        availableDates,
        availableBreakfastDates,
        today,
        reservationStart,
    })

    dispatch({
        type: types.COPY_SERVICE,
        payload: {
            id,
            overrides: {
                ...datesObject,
                ...overrides,
            },
            adminName,
        },
    })
}

export const copyService = withLogging(copyServiceAction, service => {
    const {service: name} = service

    LogsAPI.custom(`Скопирована услуга "${name}" за ${getActualDate(service)}`)
})

export const deleteServiceAction = ({id}) => ({
    type: types.DELETE_SERVICE,
    payload: {
        id,
    },
})

export const deleteService = withLogging(deleteServiceAction, service => {
    const {service: name} = service
    LogsAPI.custom(`Удалена услуга "${name}" за ${getActualDate(service)}`)
})

export const refundServiceAction = ({id, quantity}) => (
    dispatch,
    getState
) => {
    const state = getState()
    const service = findService(id, state)

    dispatch({
        type: types.REFUND_SERVICE,
        payload: {
            id,
            quantity: quantity ?? service.quantity,
        },
    })
}

export const refundService = withLogging(refundServiceAction, service => {
    const {service: name} = service
    LogsAPI.custom(
        `Выполнен возврат услуги "${name}" за ${getActualDate(service)}`
    )
})

const selectRoomModal = () => callModal(
    modalTemplates.alert({
        title: 'Не выбран номер для заселения',
        text: 'Перед изменением даты заезда выберите номер для заселения',
    })
)

export const createCheckIn = () => (dispatch, getState) => {
    const state = getState()
    const hasRoom = reservationSelectors.hasCheckedInRoom(state)
    if (!hasRoom) {
        selectRoomModal()
    }
    const earlyCheckinService = specialServiceTypesSelectors.earlyCheckinService(state)
    const firstDay = reservationSelectors.firstDay(state)
    const date = firstDay.date
    const price = reservationSelectors.checkInPrice(state)

    dispatch(
        createService({
            serviceType: 'checkin',
            name: earlyCheckInService,
            special_service_id: earlyCheckinService.pk,
            price,
            quantity: 1,
            paymentType: '',
            date,
        })
    )
}

export const createCheckOut = () => (dispatch, getState) => {

    const state = getState()
    const hasRoom = reservationSelectors.hasCheckedInRoom(state)
    if (!hasRoom) {
        selectRoomModal()
    }
    const lateCheckoutService = specialServiceTypesSelectors.lateCheckoutService(state)
    const lastDay = reservationSelectors.lastDay(state)

    const date = lastDay.date
    const price = reservationSelectors.checkOutPrice(state)

    dispatch(
        createService({
            serviceType: 'checkout',
            name: lateCheckOutService,
            special_service_id: lateCheckoutService.pk,
            price,
            quantity: 1,
            paymentType: '',
            date,
        })
    )
}

export const changeCheckInTime = value => dispatch => {
    LogsAPI.editAction('Время заезда', value)

    dispatch(changeField('check_in_time', value))
    dispatch(syncCheckIn())
}

export const changeCheckOutTime = value => dispatch => {
    LogsAPI.editAction('Время выезда', value)

    dispatch(changeField('check_out_time', value))
    dispatch(syncCheckOut())
}

export const changeCheckInPaymentType = value => (dispatch, getState) => {
    LogsAPI.editAction('Метод оплаты услуги раннего заезда', value)

    const state = getState()

    const checkIn = reservationSelectors.checkInService(state)

    dispatch(
        updateServicePaymentType({
            id: checkIn.id,
            value,
        })
    )
}

export const changeCheckOutPaymentType = value => (dispatch, getState) => {
    LogsAPI.editAction('Метод оплаты услуги позднего выезда', value)

    const state = getState()

    const checkOut = reservationSelectors.checkOutService(state)

    dispatch(
        updateServicePaymentType({
            id: checkOut.id,
            value,
        })
    )
}

export const changeCheckInPrice = value => (dispatch, getState) => {
    LogsAPI.editAction('Стоимость услуги раннего заезда', value)

    const state = getState()

    const checkIn = reservationSelectors.checkInService(state)

    dispatch(
        updateServicePrice({
            id: checkIn.id,
            value,
        })
    )
}

export const changeCheckOutPrice = value => (dispatch, getState) => {
    LogsAPI.editAction('Стоимость услуги позднего выезда', value)

    const state = getState()

    const checkOut = reservationSelectors.checkOutService(state)

    dispatch(
        updateServicePrice({
            id: checkOut.id,
            value,
        })
    )
}

const createCheckSyncAction = ({
                                   serviceSelector,
                                   checkDaySelector,
                                   checkPriceSelector,
                               }) => () => (dispatch, getState) => {
    const state = getState()

    const currentCheckService = serviceSelector(state)

    if (!currentCheckService) return

    const checkDay = checkDaySelector(state)

    if (!checkDay) return

    const nextDate = checkDay.date
    const nextPrice = checkPriceSelector(state)

    const isBought = Boolean(currentCheckService.payment_date)

    if (currentCheckService.date !== nextDate)
        dispatch(
            updateServiceDate({
                id: currentCheckService.id,
                value: nextDate,
            })
        )

    if (currentCheckService.price !== nextPrice && !isBought)
        dispatch(
            updateServicePrice({
                id: currentCheckService.id,
                value: nextPrice,
            })
        )
}

export const syncCheckIn = createCheckSyncAction({
    serviceSelector: reservationSelectors.checkInService,
    checkDaySelector: reservationSelectors.firstDay,
    checkPriceSelector: reservationSelectors.checkInPrice,
})

export const syncCheckOut = createCheckSyncAction({
    serviceSelector: reservationSelectors.checkOutService,
    checkDaySelector: reservationSelectors.lastDay,
    checkPriceSelector: reservationSelectors.checkOutPrice,
})

export const syncCheckServices = () => dispatch => {
    dispatch(syncCheckIn())
    dispatch(syncCheckOut())
}

const createCheckRemoveAction = ({
                                     serviceSelector,
                                     checkTimeField,
                                     initialCheckTime,
                                     removeAction,
                                 }) => () => (dispatch, getState) => {
    const state = getState()

    const checkService = serviceSelector(state)

    dispatch(
        removeAction({
            id: checkService.id,
        })
    )

    dispatch(changeField(checkTimeField, initialCheckTime))
}

export const deleteCheckIn = createCheckRemoveAction({
    serviceSelector: reservationSelectors.checkInService,
    checkTimeField: 'check_in_time',
    initialCheckTime: defaultCheckInTime,
    removeAction: deleteService,
})

export const deleteCheckOut = createCheckRemoveAction({
    serviceSelector: reservationSelectors.checkOutService,
    checkTimeField: 'check_out_time',
    initialCheckTime: defaultCheckOutTime,
    removeAction: deleteService,
})

export const refundCheckIn = createCheckRemoveAction({
    serviceSelector: reservationSelectors.checkInService,
    checkTimeField: 'check_in_time',
    initialCheckTime: defaultCheckInTime,
    removeAction: refundService,
})

export const refundCheckOut = createCheckRemoveAction({
    serviceSelector: reservationSelectors.checkOutService,
    checkTimeField: 'check_out_time',
    initialCheckTime: defaultCheckOutTime,
    removeAction: refundService,
})

export const refundAllServices = () => (dispatch, getState) => {
    LogsAPI.event('Возврат всех услуг')

    const state = getState()

    const services = reservationSelectors.services(state)

    for (let service of services) {
        const {id} = service
        dispatch(refundService({id}))
    }
}
